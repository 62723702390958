<template>
  <!-- 编辑入职信息 -->
  <div class="editOnboarding">
    <van-nav-bar title="编辑入职信息"
                 left-text=""
                 left-arrow
                 @click-left="onClickLeft">
    </van-nav-bar>
    <van-form @submit="onSubmit">
      <van-field class="margin_cell"
                 v-model="name"
                 name="name"
                 label="姓名"
                 placeholder="请输入"
                 input-align="right"
                 required />
      <!-- 性别 -->
      <van-field v-model="sex"
                 :label="sexLable"
                 name="sex"
                 placeholder="请选择"
                 input-align="right"
                 readonly
                 right-icon="arrow"
                 @click="showActions(sexLable)" />
      <van-field v-model="phone"
                 name="phone"
                 label="手机号码"
                 placeholder="请输入"
                 input-align="right"
                 required />
      <van-field v-model="email"
                 name="email"
                 label="个人邮箱"
                 placeholder="请输入"
                 input-align="right" />
      <!-- 证件类型 -->
      <van-field v-model="certificateType"
                 name="certificateType"
                 :label="certificateTypeLable"
                 placeholder="请选择"
                 input-align="right"
                 readonly
                 right-icon="arrow"
                 @click="showActions(certificateTypeLable)" />
      <van-field class="cell_after"
                 v-model="certificateNum"
                 name="certificateNum"
                 label="证件号码"
                 placeholder="请输入"
                 input-align="right" />
      <van-field class="margin_cell"
                 v-model="age"
                 name="age"
                 label="年龄"
                 placeholder="请输入"
                 input-align="right" />
      <van-field v-model="workYears"
                 name="workYears"
                 label="工作年限"
                 placeholder="请输入"
                 input-align="right" />
      <!-- 最高学历 -->
      <van-field v-model="education"
                 name="education"
                 :label="educationLable"
                 placeholder="请选择"
                 input-align="right"
                 readonly
                 right-icon="arrow"
                 @click="showActions(educationLable)" />
      <van-field v-model="school"
                 name="school"
                 label="毕业院校"
                 placeholder="请输入"
                 input-align="right" />
      <van-field v-model="major"
                 name="major"
                 label="毕业专业"
                 placeholder="请输入"
                 input-align="right" />
      <van-field class="cell_after"
                 v-model="company"
                 name="company"
                 label="最近工作单位"
                 placeholder="请输入"
                 input-align="right" />
      <van-field class="margin_cell"
                 readonly
                 clickable
                 required
                 :value="entryTime"
                 label="预计入职日期"
                 input-align="right"
                 name="entryTime"
                 placeholder="请选择"
                 right-icon="arrow"
                 @click="showTime = true" />
      <van-popup v-model="showTime"
                 round
                 position="bottom">
        <van-datetime-picker v-model="currentDate"
                             type="date"
                             title="选择入职日期"
                             :min-date="minDate"
                             @confirm="onConfirm"
                             @cancel="showTime = false" />
      </van-popup>
      <!-- 试用期 -->
      <van-field v-model="probation"
                 name="probation"
                 :label="probationLable"
                 placeholder="请选择"
                 input-align="right"
                 readonly
                 right-icon="arrow"
                 @click="showActions(probationLable)" />
      <!-- 合同公司 -->
      <van-field class="cell_after"
                 v-model="contractCompany"
                 name="contractCompany"
                 :label="contractCompanyLable"
                 placeholder="请选择"
                 input-align="right"
                 readonly
                 required
                 right-icon="arrow"
                 @click="showActions(contractCompanyLable)" />
      <!-- 入职部门 -->
      <van-field class="margin_cell"
                 v-model="department"
                 name="department"
                 label="入职部门"
                 placeholder="请选择"
                 input-align="right"
                 readonly
                 right-icon="arrow" />
      <!-- 职务 -->
      <van-field v-model="position"
                 name="position"
                 label="职务"
                 placeholder="请选择"
                 input-align="right"
                 readonly
                 right-icon="arrow" />
      <!-- 入职岗位 -->
      <van-field v-model="job"
                 name="job"
                 label="入职岗位"
                 placeholder="请选择"
                 input-align="right"
                 readonly
                 right-icon="arrow" />
      <!-- 职级 -->
      <van-field v-model="rank"
                 name="rank"
                 label="职级"
                 placeholder="请选择"
                 input-align="right"
                 readonly
                 right-icon="arrow" />
      <!-- 工作性质 -->
      <van-field v-model="workNature"
                 name="workNature"
                 :label="workNatureLable"
                 placeholder="请选择"
                 input-align="right"
                 readonly
                 required
                 right-icon="arrow"
                 @click="showActions(workNatureLable)" />
      <van-field class="cell_after"
                 v-model="workPlace"
                 name="workPlace"
                 label="工作地点"
                 placeholder="请输入"
                 input-align="right" />
      <van-field class="margin_cell"
                 v-model="workCity"
                 name="workCity"
                 label="工作城市"
                 placeholder="请输入"
                 input-align="right" />
      <!-- 招聘渠道  -->
      <van-field class="cell_after"
                 v-model="recruitChannels"
                 name="recruitChannels"
                 :label="recruitChannelsLable"
                 placeholder="请选择"
                 input-align="right"
                 readonly
                 right-icon="arrow"
                 @click="showActions(recruitChannelsLable)" />
      <div class="footer_btn">
        <van-button type="info"
                    native-type="submit">保存</van-button>
      </div>
    </van-form>
    <van-action-sheet v-model="show"
                      :actions="actions"
                      :title="actionLable"
                      cancel-text="取消"
                      :closeable="true"
                      @select="onSelect">
      <!-- <template #default>
          <div v-for="(acs,index) in actions"
               :key="index"
               @click="onSelect(acs)">
            {{acs.name}}
          </div>
        </template> -->
    </van-action-sheet>
  </div>
</template>

<script>
export default {
  data () {
    return {
      show: false,
      actions: [],
      actionLable: '',
      name: '',
      sex: '',
      sexLable: '性别',
      phone: '',
      email: '',
      certificateType: '',
      certificateTypeLable: '证件类型',
      certificateNum: '',
      age: '',
      workYears: '',
      education: '',
      educationLable: '最高学历',
      school: '',
      major: '',
      company: '',
      entryTime: '',
      showTime: false,
      minDate: new Date(),
      // maxDate: new Date(2025, 10, 1),
      currentDate: new Date(),
      probation: '',
      probationLable: '试用期',
      contractCompany: '',
      contractCompanyLable: '合同公司',
      department: '',
      position: '',
      job: '',
      rank: '',
      workNature: '',
      workNatureLable: '工作性质',
      workPlace: '',
      workCity: '',
      recruitChannels: '',
      recruitChannelsLable: '招聘渠道'
    }
  },
  methods: {
    onClickLeft () {
      this.$router.push("/inductionInfo")
    },
    onSubmit (values) {
      console.log('submit', values);
    },
    showActions (item) {
      // 根据不同类型去查actions
      switch (item)
      {
        case '性别':
          this.actions = [{ name: '男', className: 'sex' }, { name: '女', className: 'sex' }]
          break;
        case '证件类型':
          this.actions = [{ name: '身份证', className: 'certificateType' }, { name: '驾驶证', className: 'certificateType' }, { name: '居住证', className: 'certificateType' }]
          break;
        case '最高学历':
          this.actions = [{ name: '研究生', className: 'education' }, { name: '本科', className: 'education' }, { name: '专科', className: 'education' }]
          break;
        case '试用期':
          this.actions = [{ name: '三个月', className: 'probation' }, { name: '两个月', className: 'probation' }, { name: '一个月', className: 'probation' }]
          break;
        case '合同公司':
          this.actions = [{ name: '华跃科技', className: 'contractCompany' }, { name: '腾讯科技', className: 'contractCompany' }, { name: '阿里集团', className: 'contractCompany' }]
          break;
        case '工作性质':
          this.actions = [{ name: '全职', className: 'workNature' }, { name: '兼职', className: 'workNature' }, { name: '临时工', className: 'workNature' }]
          break;
        case '招聘渠道':
          this.actions = [{ name: 'BOSS直聘', className: 'recruitChannels' }, { name: '51job', className: 'recruitChannels' }, { name: '前程无忧', className: 'recruitChannels' }]
          break;

        default:
          break;
      }
      this.show = true
      this.actionLable = item
    },
    // 下拉选择确认项
    onSelect (action) {
      // 默认情况下点击选项时不会自动收起
      // 可以通过 close-on-click-action 属性开启自动收起
      this.show = false;
      // item.value = action.name
      switch (action.className)
      {
        case 'sex':
          this.sex = action.name
          break;
        case 'certificateType':
          this.certificateType = action.name
          break;
        case 'education':
          this.education = action.name
          break;
        case 'probation':
          this.probation = action.name
          break;
        case 'contractCompany':
          this.contractCompany = action.name
          break;
        case 'workNature':
          this.workNature = action.name
          break;
        case 'recruitChannels':
          this.recruitChannels = action.name
          break;
        default:
          break;
      }
    },
    // 选择预入职日期确认
    onConfirm (value) {
      let dateTime = ''
      if (value)
      {
        let y = value.getFullYear()
        let m = value.getMonth() + 1
        m = m < 10 ? ('0' + m) : m
        let d = value.getDate()
        d = d < 10 ? ('0' + d) : d
        // let h = value.getHours()
        // h = h < 10 ? ('0' + h) : h
        // let M = value.getMinutes()
        // M = M < 10 ? ('0' + M) : M
        //  let s =date.getSeconds()
        //  s = s < 10 ? ('0' + s) : s
        // dateTime = y + '-' + m + '-' + d + ' ' + h + ':' + M;
        dateTime = y + '-' + m + '-' + d
      }
      this.entryTime = dateTime
      this.showTime = false
    },
  }
}
</script>

<style lang="less" scoped>
.editOnboarding {
  height: 100vh;
  overflow: hidden;
  .van-nav-bar {
    background: #2b8df0;
    height: 92px;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    .van-icon-ellipsis {
      color: #fff;
    }
  }
  .van-form {
    overflow: auto;
    height: calc(100% - 235px);
    ::v-deep .van-field__label {
      color: #000;
    }
    .margin_cell {
      margin-top: 20px;
    }
    .cell_after::after {
      border-bottom: unset;
    }
  }
  .footer_btn {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 120px;
    padding: 20px;
    box-sizing: border-box;
    background: #fff;
    justify-content: center;
    .van-button {
      width: 80%;
      border-radius: 10px;
      .van-button__text {
        font-size: 32px;
        font-family: Source Han Sans CN;
      }
    }
  }
}
</style>